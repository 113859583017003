var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "article-container" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "article-container-tab",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "成交公告", name: "deal" } },
            [_vm.activeName == "deal" ? _c("News", { ref: "deal" }) : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "新闻动态", name: "news" } },
            [_vm.activeName == "news" ? _c("News", { ref: "news" }) : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "系统公告", name: "system" } },
            [
              _vm.activeName == "system"
                ? _c("News", { ref: "system" })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }