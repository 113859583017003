<template>
  <div class="article-container">
    <el-tabs class="article-container-tab" v-model="activeName">
      <el-tab-pane label="成交公告" name="deal">
        <News ref="deal" v-if="activeName == 'deal'" />
      </el-tab-pane>
      <el-tab-pane label="新闻动态" name="news">
        <News ref="news" v-if="activeName == 'news'" />
      </el-tab-pane>
      <el-tab-pane label="系统公告" name="system">
        <News ref="system" v-if="activeName == 'system'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import News from '../component/news.vue';
export default {
  components: {
    News
  },
  data() {
    return {
      activeName: ''
    };
  },
  watch: {
    activeName: function(val) {
      this.$nextTick(() => {
        this.$set(this.$refs[val], 'type', val);
      });
    }
  },
  created() {
    if (this.$route.query.type) this.activeName = this.$route.query.type;
  }
};
</script>
<style lang="less" scoped>
.article-container {
  & /deep/ .el-tabs__nav-scroll {
    height: 44px;
    background-color: #fff;
    padding-left: 55px;
  }
  & /deep/ .el-tabs__nav-wrap::after {
    content: '';
    height: 0;
  }
  width: 1200px;
  margin: 0 auto;
  &-tab {
    padding-top: 23px;
  }
}
</style>
