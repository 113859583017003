<template>
  <div class="news">
    <ul v-if="info.length" class="news-ul">
      <li v-for="(item, index) in info" :key="index">
        <p class="date">{{ item.updateTime }}</p>
        <div class="new">
          <p @click="toArticleDetail(item)">{{ item.title }}</p>
        </div>
      </li>
    </ul>
    <empty text="暂无数据" v-else />
    <el-pagination style="float: right;" hide-on-single-page background layout="prev, pager, next" :current-page.sync="currentPage" :page-size="10" :total="total" @current-change="changePage">
    </el-pagination>
  </div>
</template>
<script>
import empty from './empty.vue';
import {showNoticeInfo} from '../api';
export default {
  data() {
    return {
      info: [],
      type: '',
      total: 0,
      currentPage: 1
    };
  },
  components: {
    empty
  },
  watch: {
    type: function(val) {
      this.getData(1, val);
    }
  },
  methods: {
    handleSizeChange() {},
    async getData(page, type) {
      let res = await showNoticeInfo({noticeType: type, page});
      if (res.errno == 0) {
        this.info = res.data.data;
        this.total = res.data.total;
      }
    },
    changePage(val) {
      this.currentPage = val;
      this.getData(val, this.type);
    },
    toArticleDetail(item) {
      let routeUrl = this.$router.resolve({
        name: 'home-articleDetail',
        query: {articleId: item.id, type: this.type}
      });
      window.open(routeUrl.href, '_blank');
    }
  }
};
</script>
<style lang="less" scoped>
.news {
  padding-top: 20px;
  &-ul {
    li {
      background-color: #fff;
      height: 50px;
      line-height: 50px;
      display: flex;
      margin-bottom: 5px;
      .date {
        color: #008df4;
        text-align: center;
        height: 100%;
        background-color: #eef8ff;
        width: 175px;
      }
      .new {
        cursor: pointer;
        padding-left: 15px;
        height: 100%;
        a:hover {
          color: #008df4;
        }
      }
    }
  }
}
</style>
