var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "news" },
    [
      _vm.info.length
        ? _c(
            "ul",
            { staticClass: "news-ul" },
            _vm._l(_vm.info, function (item, index) {
              return _c("li", { key: index }, [
                _c("p", { staticClass: "date" }, [
                  _vm._v(_vm._s(item.updateTime)),
                ]),
                _c("div", { staticClass: "new" }, [
                  _c(
                    "p",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toArticleDetail(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                ]),
              ])
            }),
            0
          )
        : _c("empty", { attrs: { text: "暂无数据" } }),
      _c("el-pagination", {
        staticStyle: { float: "right" },
        attrs: {
          "hide-on-single-page": "",
          background: "",
          layout: "prev, pager, next",
          "current-page": _vm.currentPage,
          "page-size": 10,
          total: _vm.total,
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function ($event) {
            _vm.currentPage = $event
          },
          "current-change": _vm.changePage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }